
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else >
<!-- {{ order_list_view }} -->
			<h1 class="mt-2 mb-5 no-print">Drivers Bag :</h1>

			<!-- <p class="my-5">{{order_list}}</p> -->
			<form @submit.prevent="addDriverBags" autocomplete="off" class="no-print">
				<v-layout row wrap>

					<v-flex xs12 lg4 xl4 md5 sm5>
						<v-autocomplete class="mx-1" clearable :items="drivers" v-model="driver_bags.driver_id" dense filled outlined item-text="driver_name" item-value="driver_id" :return-object="false" :label="$store.getters.language.data.drivers.driver_name">
						</v-autocomplete>
					</v-flex>
					<v-flex xs12 lg4 xl4 md5 sm5>
						<v-autocomplete class="mx-1" clearable :items="drivers" v-model="driver_bags.driver_id" dense filled outlined item-text="driver_phone" item-value="driver_id" :return-object="false" :label="$store.getters.language.data.drivers.driver_phone">
						</v-autocomplete>
					</v-flex>

					<!-- <v-flex xs12 lg4 xl4 md5 sm5>
						<v-text-field v-model="driver_bags.driver_bag_date" type="datetime-local" :label="$store.getters.language.data.driver_bags.driver_bag_date" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg4 xl4 md5 sm5>
						<v-text-field v-model="driver_bags.driver_bag_date_delivery" type="datetime-local" :label="$store.getters.language.data.driver_bags.driver_bag_date_delivery" dense class="mx-1" filled outlined>
						</v-text-field>
					</v-flex> -->
					<v-flex xs12 lg4 xl4 md5 sm5>
						<v-select class="mx-1" clearable :items="['out','delivered']" v-model="driver_bags.driver_bag_status" dense filled outlined item-text="driver_bag_status" item-value="driver_bag_status" :return-object="false" :label="$store.getters.language.data.driver_bags.driver_bag_status" disabled>
						</v-select>
					</v-flex>

					<v-flex xs12 lg4 xl4 md5 sm5>
						<v-text-field v-model="driver_bags.driver_bag_total" type="text" :label="$store.getters.language.data.driver_bags.driver_bag_total" dense class="mx-1" filled outlined disabled>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg4 xl4 md5 sm5>
						<v-select class="mx-1" clearable :items="users" v-model="driver_bags.user_id" dense filled outlined item-text="user_username" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_username" disabled>
						</v-select>
					</v-flex>

					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.driver_bags.add_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form>
			<v-flex xs12 lg2 xl2 md3 sm4 class="no-print">
				<v-text-field v-model="barcode" type="text" label="Barcode" dense class="mx-1" filled outlined @keydown.enter="selectMatchingValue">

				</v-text-field>
			</v-flex>
			<v-flex xs12 lg2 xl2 md3 sm4 class="no-print">

				<v-autocomplete class="mx-1" clearable :items="packed_orders" v-model="driver_bag_orders.packed_order_id" dense filled outlined item-text="packed_order_id" item-value="packed_order_id" :return-object="false" :label="$store.getters.language.data.packed_orders.packed_order_id" @keydown.enter="selectMatchingValue"></v-autocomplete>
			</v-flex>
			<form @submit.prevent="addDriverBagOrders" autocomplete="off" class="no-print">
				<v-layout row wrap>

					<!-- <v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="driver_bags" v-model="driver_bag_orders.driver_bag_id" dense filled outlined item-text="driver_bag_id" item-value="driver_bag_id" :return-object="false" :label="$store.getters.language.data.driver_bags.driver_bag_id">
						</v-select>
					</v-flex> -->

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="driver_bag_orders.driver_bag_order_total_dinar" type="text" :label="$store.getters.language.data.driver_bag_orders.driver_bag_order_total_dinar" dense class="mx-1" filled outlined required disabled>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>

						<v-select class="mx-1" clearable :items="['out', 'delivered', 'returned']" v-model="driver_bag_orders.driver_bag_order_status" dense filled outlined item-text="driver_bag_order_status" item-value="driver_bag_order_status" :return-object="false" :label="$store.getters.language.data.driver_bag_orders.driver_bag_order_status" disabled>
						</v-select>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="users" v-model="driver_bag_orders.user_id" dense filled outlined item-text="user_username" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_username" disabled>
						</v-select>
					</v-flex>

					<!-- <v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.driver_bag_orders.add_btn}}</v-btn>
					</v-flex> -->
					<!-- button to add order to order_list -->
					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="primary" :loading="loading_btn" type="submit" @click="addOrderList">Add</v-btn>
					</v-flex>
				</v-layout>
			</form>
			<v-layout row wrap >

				<v-flex xs12>
					<!-- create a table for order_list -->

					<h3>Driver Name: {{driver_detail.driver_name}} </h3>
					<h3>Driver Phone:
						{{driver_detail.driver_phone}}</h3>
					<h3>Total To Recive: IQD {{driver_bags.driver_bag_total.toLocaleString()}}</h3>
					<h3>Total : IQD {{driver_bags.driver_bag_final_price.toLocaleString()}}</h3>
					<v-divider class="my-4"></v-divider>
					<table width="100%" border="1" style="border-collapse:collapse" class="table table-bordered table-striped">
						<thead style="background-color:black; color:white">
							<tr>
                                <th class="py-3">#</th>
								<th class="py-3">ID</th>
								<th class="py-3"> Name</th>
								<th class="py-3"> Phone</th>
								<th class="py-3"> City</th>
								<th class="py-3"> Address</th>
                                <th class="py-3">Delivery Price</th>
								<th class="py-3">Total Dinar</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(row, index) in order_list_view" :key="index">
                                <td class="pa-3">{{index + 1}}</td>
								<td class="pa-3">{{row.packed_order_id}}</td>
								<td class="pa-3">{{row.customer_name}}</td>
								<td class="pa-3">{{row.customer_phone}} <br> {{ row.customer_phone_2 }}</td>
								<td class="pa-3">{{row.customer_city}}</td>
								<td class="pa-3">{{row.customer_address}}</td>
                                <td class="pa-3">{{ (row.packed_order_delivery_price || 0).toLocaleString() }}</td>

								<td class="pa-3">{{(row.driver_bag_order_total_dinar||0).toLocaleString()}}</td>
								<td class="no-print">
									<v-btn color="error" icon class="mx-1 " @click="deleteOrderList(index)">
										<v-icon> mdi-delete-outline </v-icon>
									</v-btn>
								</td>
							</tr>
						</tbody>
					</table>
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.driver_bags.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.driver_bags.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deleteDriverBags(selected_driver_bags.driver_bag_id)">
						{{$store.getters.language.data.driver_bags.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import { del } from 'vue'
	import requests from './../../requests/driver_bags.request.js'
	import bagrequests from './../../requests/driver_bag_orders.request.js'
	import packedrequests from './../../requests/packed_orders.request.js'

	export default {
		data() {
			return {
				driver_bags: {
					driver_bag_status: 'out',
					driver_bag_total: 0,
					driver_bag_final_price: 0,
					driver_bag_final_total_price: 0,
				},
				driver_detail: {},
				search: '',
				loading: true,
				loading_btn: false,
				selected_rows: [],
				driver_bag_orders: {
					driver_bag_order_status: 'out',
					driver_bag_order_total_dinar: 0,
					driver_bag_final_total_price: 0,
				},
				order_list: [],
				order_list_view: [],
				barcode: '',

				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_driver_bags: {},
				delete_dialog: false,
				headers: [


					{
						text: this.$store.getters.language.data.drivers.driver_id,
						align: 'start',
						sortable: true,
						value: 'driver_id',
					},
					{
						text: this.$store.getters.language.data.driver_bags.driver_bag_date,
						align: 'start',
						sortable: true,
						value: 'driver_bag_date',
					},
					{
						text: this.$store.getters.language.data.driver_bags.driver_bag_date_delivery,
						align: 'start',
						sortable: true,
						value: 'driver_bag_date_delivery',
					},
					{
						text: this.$store.getters.language.data.driver_bags.driver_bag_status,
						align: 'start',
						sortable: true,
						value: 'driver_bag_status',
					},
					{
						text: this.$store.getters.language.data.driver_bags.driver_bag_total,
						align: 'start',
						sortable: true,
						value: 'driver_bag_total',
					},
					{
						text: this.$store.getters.language.data.users.user_id,
						align: 'start',
						sortable: true,
						value: 'user_id',
					}, {
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'driver_bag_id',
					}
				],
			}
		},
		watch: {
			'driver_bag_orders.packed_order_id'(newVal, oldVal) {
				// Find the selected packed order based on the ID
				const selectedOrder = this.packed_orders.find(order => order.packed_order_id === newVal);
				if (selectedOrder) {
					// Update the price value in driver_bag_orders
					this.driver_bag_orders.driver_bag_order_total_dinar = selectedOrder.total_dinar; // Replace 'price' with the correct property name for the price in your 'packed_orders' array
					this.driver_bag_orders.driver_bag_final_total_price = selectedOrder.packed_order_total_dinar; // Replace 'price' with the correct property name for the price in your 'packed_orders' array
				}
			},
			// when driver_bags.driver_id changes, update driver_detail
			'driver_bags.driver_id'(newVal, oldVal) {
				// Find the selected packed order based on the ID
				const selectedDriver = this.drivers.find(driver => driver.driver_id === newVal);
				if (selectedDriver) {
					// Update the price value in driver_bag_orders
					this.driver_detail = selectedDriver; // Replace 'price' with the correct property name for the price in your 'packed_orders' array
				}
			},
		},
		computed: {
			drivers() {
				return this.$store.getters.drivers_list
			},
			users() {
				return this.$store.getters.users_list
			},
			user() {
				return this.$store.getters.user
			},
			customers() {
				return this.$store.getters.customers_list
			},
		},
		mounted() {
			// this.readDriverBags();
			this.readPackedOrders();
			this.driver_bags.user_id = this.user.user_id
			this.driver_bag_orders.user_id = this.user.user_id
		},
		methods: {
			selectMatchingValue() {
				const enteredValue = this.barcode; // Trimming whitespace
				const matchedOrder = this.packed_orders.find(
					packed_order => packed_order.packed_order_id == this.barcode
				);

				if (matchedOrder) {
					this.driver_bag_orders.packed_order_id = matchedOrder.packed_order_id;
					this.driver_bag_orders.driver_bag_order_total_dinar = matchedOrder.total_dinar;
					this.driver_bag_orders.driver_bag_final_total_price = matchedOrder.packed_order_total_dinar;
                    this.driver_bag_orders.packed_order_delivery_price = matchedOrder.packed_order_delivery_price;
					this.addOrderList();
					// put the focus back on the input field
					this.$refs.barcode.focus();
					// Do something with the matchedOrder, e.g., assign it to the model

				} else {
					// Handle the case where no match is found
					console.log('No matching order found.');
					this.snackbar.value = true
					this.snackbar.text = 'packed order id not found'
					this.snackbar.color = 'warning'
					this.barcode = '';
					this.$refs.barcode.focus();
				}
			},
			addOrderList() {
				// if this.driver_bag_orders.packed_order_id is in this.order_list then return
				if (this.order_list.find(order => order.packed_order_id === this.driver_bag_orders.packed_order_id)) {
					this.snackbar.value = true
					this.snackbar.text = 'Order already added'
					this.snackbar.color = 'error'
					this.barcode = '';
					return
				}
				if (this.driver_bag_orders.packed_order_id == null) {
					this.snackbar.value = true
					this.snackbar.text = 'Please select a packed order'
					this.snackbar.color = 'error'
					return
				}
				console.log(this.driver_bag_orders)
				this.order_list.push(this.driver_bag_orders)

				this.order_list_view.push(this.driver_bag_orders)
				// order_list_view.push driver_bag_order and the customer name and phone from packed_order while they have the same packed_order_id
				// Find the selected packed order based on the ID
				const selectedOrder = this.packed_orders.find(order => order.packed_order_id === this.driver_bag_orders.packed_order_id);

				if (selectedOrder) {
					// Create a new object with selected properties from selectedOrder
					const updatedOrder = {
						customer_name: selectedOrder.customer_username,
						customer_phone: selectedOrder.customer_phone,
                        customer_phone_2: selectedOrder.customer_phone_2,
						customer_city: selectedOrder.customer_city,
						customer_address: selectedOrder.customer_address,
						driver_bag_order_total_dinar: selectedOrder.total_dinar,
						driver_bag_final_total_price: selectedOrder.packed_order_total_dinar,
						packed_order_id: selectedOrder.packed_order_id,
                        packed_order_delivery_price: selectedOrder.packed_order_delivery_price

					};

					// Assign the new object to the last element of order_list_view
					this.order_list_view[this.order_list_view.length - 1] = updatedOrder;
				}

				this.driver_bags.driver_bag_total = this.driver_bags.driver_bag_total + this.driver_bag_orders.driver_bag_order_total_dinar
				this.driver_bags.driver_bag_final_price = this.driver_bags.driver_bag_final_price + this.driver_bag_orders.driver_bag_final_total_price

				this.driver_bag_orders = {
					user_id: this.user.user_id,
					driver_bag_order_status: 'out',
				}
				this.barcode = '';
				this.snackbar.value = true
				this.snackbar.text = 'Order added successfully'
				this.snackbar.color = 'success'
			},
			deleteOrderList(index) {
				this.driver_bags.driver_bag_total = this.driver_bags.driver_bag_total - this.order_list[index].driver_bag_order_total_dinar
				this.driver_bags.driver_bag_final_price = this.driver_bags.driver_bag_final_price - this.order_list[index].driver_bag_final_total_price
				this.order_list.splice(index, 1)
				this.order_list_view.splice(index, 1)
			},
			readPackedOrders() {
				this.loading = true
				packedrequests.getPackedOrdersByColumn('packed_order_status', 'ready').then(r => {
					if (r.status == 200) {
						this.packed_orders = r.data.rows
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read DriverBagOrders',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading = false
					})
			},
			addDriverBagOrdersList() {
				this.loading_btn = true
				console.log(this.order_list)
				// delete driver_bag_final_total_price
				this.order_list.forEach(order => {
					delete order.driver_bag_final_total_price
					delete order.driver_bag_final_total_price
                    delete order.packed_order_delivery_price
				})
				bagrequests.createDriverBagOrdersList(
					{
						list: this.order_list,
					}
				).then(r => {
					if (r.status == 200) {
						this.driver_bag_orders = {
							user_id: this.user.user_id,
							driver_bag_order_status: 'out',
						}
						this.order_list = []
						this.order_list_view = []
						this.snackbar = {
							value: true,
							text: 'DriverBagOrders Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add DriverBagOrders',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			addDriverBags() {
				this.loading_btn = true
				delete this.driver_bags.driver_bag_final_total_price

				requests.createDriverBags(this.driver_bags).then(r => {
					if (r.status == 200) {
						// for each order in order_list add driver_bag_id = r.data.new_data.driver_bag_id
						this.order_list.forEach(order => {
							order.driver_bag_id = r.data.new_data.driver_bag_id
						})
						this.addDriverBagOrdersList();
						this.driver_bags = {
							driver_bag_status: 'out',
							user_id: this.user.user_id,
							driver_bag_total: 0,
						}
						this.rows.push(
							r.data.new_data
						)
						this.driver_bags.driver_bag_total = 0
						this.driver_bags.driver_bag_final_price = 0
						this.snackbar = {
							value: true,
							text: 'DriverBags Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add DriverBags',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deleteDriverBags(driver_bag_id) {
				requests.deleteDriverBags(driver_bag_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.driver_bag_id != driver_bag_id
						})
						this.snackbar = {
							value: true,
							text: 'DriverBags Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deleteDriverBagsList() {
				let ids = this.selected_rows.map(m => m.driver_bag_id)
				requests.deleteDriverBagsList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.driver_bag_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' DriverBags Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			readDriverBags() {
				this.loading = true
				requests.getAllDriverBags().then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read DriverBags',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read DriverBags',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectDriverBags(i) {
				this.selected_driver_bags = i
				this.delete_dialog = true
			},
		},
	}
</script>
                    